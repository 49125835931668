const flypilotFetchWPRestAPI = async (endpoint, data = []) => {
  if (endpoint.startsWith('/')) {
    endpoint = process.env.REACT_APP_BASE_URL + endpoint
  }

  const rest_response = await fetch(endpoint, data)
  return await rest_response.json()
}

export const bedroomOptions = [
  { label: 'Studio', value: '0', checked: false },
  { label: '1 Bedroom', value: '1', checked: false },
  { label: '2 Bedroom', value: '2', checked: false },
  { label: '3 Bedroom', value: '3', checked: false },
]

export const neighborhoodOptions = [
  { label: 'Harlem', value: 'Harlem', checked: false },
  { label: 'Chelsea', value: 'Chelsea', checked: false },
  { label: 'Upper West Side', value: 'Upper West Side', checked: false },
  { label: 'Washington Heights', value: 'Washington Heights', checked: false },
  { label: 'East Village', value: 'East Village', checked: false },
]

export const priceOptions = [
  { label: '$0-1000', value: '$0-1000', checked: false },
  { label: '$1000-3000', value: '$1000-3000', checked: false },
  { label: '$3000-6000', value: '$3000-6000', checked: false },
  { label: '$6000+', value: '$6000+', checked: false },
]

export default flypilotFetchWPRestAPI
