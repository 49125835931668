import React, { useState, useEffect } from 'react'
import flypilotFetchWPRestAPI from '../api/wordpress'
import { Link } from '../components/Link'

const AnnouncementBanner = (props) => {
  const [announcementBannerText, setAnnouncementBannerText] = useState()
  const [announcementBannerLink, setAnnouncementBannerLink] = useState()
  const [announcementIcon, setAnnouncementIcon] = useState()
  useEffect(() => {
    const optionsPageEndpoint =
      process.env.REACT_APP_BASE_URL + '/wp-json/acf/v3/options/options'
    flypilotFetchWPRestAPI(optionsPageEndpoint).then((result) => {
      const announcementBannerLinkResult = result?.acf?.link?.url
      const announcementBannerTextResult = result?.acf?.text
      const announcementIconResult = result?.acf?.announcement_icon?.url
      setAnnouncementBannerLink(announcementBannerLinkResult)
      setAnnouncementBannerText(announcementBannerTextResult)
      setAnnouncementIcon(announcementIconResult)
    })
  }, [])

  if (!announcementBannerText) {
    return <></>
  }

  return (
    <Link className="announcement_banner h4" href={announcementBannerLink}>
      <img alt="" src={announcementIcon} />
      {announcementBannerText}
    </Link>
  )
}

export default AnnouncementBanner
