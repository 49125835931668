import {useEffect, useState} from 'react'
import flypilotFetchWPRestAPI from '../api/wordpress'

function useMenus() {
  const [menus, setMenus] = useState({})

  function loadMenus() {
    flypilotFetchWPRestAPI('/wp-json/wp/v2/menu')
      .then((results) => setMenus(results))
  }

  useEffect(loadMenus, [])

  return menus
}

export default useMenus
