import { Link as ReactDomLink } from "react-router-dom";

export function Link({ target, href, onClick, children, ...rest }) {
  const finalHref = href || '/'

  function scrollToTopIfNotOnClick(e) {
    if (onClick) {
      // return onClick(e);
    } 
    // only scroll to top if not onClick
    window.scrollTo(0, 0);
  }


  if (target === "_blank") {
    return (
      <a target={target} href={finalHref} onClick={scrollToTopIfNotOnClick} {...rest}>
        {children}
      </a>
    );
  }

  return (
    <ReactDomLink target={target} to={finalHref} onClick={scrollToTopIfNotOnClick} {...rest}>
      {children}
    </ReactDomLink>
  );
}
