import {useEffect, useState} from 'react'
import flypilotFetchWPRestAPI from '../api/wordpress'

function useMenu(name) {
  const [menu, setMenu] = useState([])
  const [loading, setLoading] = useState(false)

  function loadMenu() {


    if (!name) {
      setMenu(null)
      setLoading(false)
      return
    }

    setLoading(true)

    flypilotFetchWPRestAPI(`${process.env.REACT_APP_BASE_URL}/wp-json/wp/v2/menu`)
      .then((results) => {
        if (results.length === 0) {
          return setMenu(null)
        }
        const result = results[name]
        setMenu(result)
      }).then(() => setLoading(false))
  }

  useEffect(loadMenu, [name])

  return menu
}

export default useMenu
