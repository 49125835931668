import React, { useState, useEffect, useRef } from 'react'
import flypilotFetchWPRestAPI from '../api/wordpress'
import spinner from '../assets/images/contact/spinner.gif'
import Logo from '../assets/images/Logo.svg'
import Instagram from '../assets/images/Instagram.svg'
import Facebook from '../assets/images/Facebook.svg'
import useMenu from '../hooks/useMenu'
import { useCookie } from '../hooks/useCookie'
import { Link } from './Link'
import TagManager from 'react-gtm-module'

const Footer = (props) => {
  const menu = useMenu('footerMenu')
  const [instagramIcon, setInstagramIcon] = useState(Instagram)
  const [facebookIcon, setFacebookIcon] = useState(Facebook)
  const [instagramLink, setInstagramLink] = useState()
  const [facebookLink, setFacebookLink] = useState()
  const [subscribeText, setSubscribeText] = useState()
  const [siteLogo, setSiteLogo] = useState(Logo)
  const emailInputRef = useRef()
  const brokerInputRef = useRef()
  const [formSuccess, setformSucess] = useState(false)
  const [formMessage, setformMessage] = useState()
  const [spinnerStyle, setSpinnerStyle] = useState({ display: 'none' })
  const [btnClicked, setBtnClicked] = useState(null)
  const [menuClicked, setMenuClicked] = useState(null)
  useEffect(() => {
    const optionsPageEndpoint =
      process.env.REACT_APP_BASE_URL + '/wp-json/acf/v3/options/options'
    flypilotFetchWPRestAPI(optionsPageEndpoint).then((result) => {
      const instagramIconResult = result?.acf?.instagram_icon?.url
      const facebookIconResult = result?.acf?.facebook_icon?.url
      const instagramLinkResult = result?.acf?.instagram_link
      const facebookLinkResult = result?.acf?.facebook_link
      const subscribeTextResult = result?.acf?.subscribe_text
      const siteLogoResult = result?.acf?.site_logo?.url
      setInstagramIcon(instagramIconResult)
      setFacebookIcon(facebookIconResult)
      setInstagramLink(instagramLinkResult)
      setFacebookLink(facebookLinkResult)
      setSubscribeText(subscribeTextResult)
      setSiteLogo(siteLogoResult)
    })
  }, [])

  useCookie('buttons', btnClicked)
  useCookie('menus', menuClicked)

  function submitHandler(event) {
    event.preventDefault()

    setSpinnerStyle({ display: 'block' })
    setformMessage('')

    const email = emailInputRef.current.value
    const broker = brokerInputRef.current.checked

    const formData = {
      input_1: email,
      input_2_1: broker,
    }

    const formEndpoint =
      process.env.REACT_APP_BASE_URL + '/wp-json/gf/v2/forms/1/submissions'
    flypilotFetchWPRestAPI(formEndpoint, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    }).then((result) => {
      const confirmationMessage = result?.confirmation_message
      const confirmationType = result?.confirmation_type
      const isValid = result?.is_valid
      const validationMessages = result?.validation_messages
      setSpinnerStyle({ display: 'none' })
      if (!isValid) {
        if (validationMessages) {
          setformMessage(validationMessages[Object.keys(validationMessages)[0]])
          return
        }
        setformMessage(result.message)
      }

      if (confirmationType == 'message') {
        setformSucess(true)
        setformMessage(confirmationMessage)
        TagManager.dataLayer({
          dataLayer: {
            event: 'FormSubmitted',
            category: 'event',
          },
        })
      }
    })
  }

  return (
    <footer className="site-footer">
      <div className="leftColumn">
        <Link className="logo" href="/">
          <img alt="GoNoFee Logo" src={siteLogo} />
        </Link>
        <div className="socialMediaContainer">
          <Link
            target="_blank"
            className="socialMediaIcon"
            href={instagramLink}
          >
            <img
              alt="Instagram"
              src={instagramIcon}
              onClick={() => setBtnClicked('instagram')}
            />
          </Link>
          <Link target="_blank" className="socialMediaIcon" href={facebookLink}>
            <img
              alt="Facebook"
              src={facebookIcon}
              onClick={() => setBtnClicked('facebook')}
            />
          </Link>
        </div>
      </div>
      <div className="pageLinksColumn">
        {menu.map((link) => (
          <div onClick={() => setMenuClicked(link.title)}>
            <Link
              key={link.title}
              className="pageLink h4"
              target={link.target}
              href={link.url}
            >
              {link.title}
            </Link>
          </div>
        ))}
      </div>
      <div className="subscribeColumn">
        <h2>{subscribeText}</h2>
        {!formSuccess && (
          <form onSubmit={submitHandler}>
            <input
              type="text"
              placeholder="Email Address"
              ref={emailInputRef}
            />
            <label className="checkbox">
              <input type="checkbox" ref={brokerInputRef} /> I am a Broker
            </label>
            <div className="submit">
              <button
                className="button black"
                onClick={() => setBtnClicked('submit email')}
              >
                Submit
              </button>
              <img src={spinner} style={spinnerStyle} />
            </div>
          </form>
        )}
        <div className="contact_form_message">
          <div dangerouslySetInnerHTML={{ __html: formMessage }} />
        </div>
      </div>
    </footer>
  )
}

export default Footer
