import { useEffect, useState, useContext } from 'react'

import spinner from '../../assets/images/contact/spinner.gif'
import CloseIcon from '../../assets/images/close-icon.png'
import flypilotFetchWPRestAPI from '../../api/wordpress'

import { SearchButton } from '../Button'
import { getCookie } from '../../hooks/useCookie'
import { AppContext } from '../../App'

const Modal = ({ onClose }) => {
  const context = useContext(AppContext)
  const [email, setEmail] = useState('')
  const [popupTitle, setPopupTitle] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const options = context?.options

  const formatValues = (value) => value?.replaceAll('//', '; ') || ''

  const onSubmit = (e) => {
    e.preventDefault()

    if (!email) {
      return setError('Please add your email')
    }

    setLoading(true)
    

    const neighborhoods = formatValues(getCookie('neighborhoods'))
    const properties = formatValues(getCookie('properties'))
    const apartments = formatValues(getCookie('apartments'))
    const amenities = formatValues(getCookie('amenities'))
    const bedrooms = formatValues(getCookie('bedrooms'))
    const rent = formatValues(getCookie('rent'))
    const buttons = formatValues(getCookie('buttons'))
    const menus = formatValues(getCookie('menus'))
    const envelope = formatValues(getCookie('envelope'))
    const points_interest = formatValues(getCookie('points_interest'))

    const formData = {
      input_1: email,
      input_2: neighborhoods,
      input_3: properties,
      input_4: apartments,
      input_5: amenities,
      input_6: bedrooms,
      input_7: menus,
      input_8: rent,
      input_9: buttons,
      input_10: envelope,
      input_11: points_interest,
    }

    const formEndpoint =
      process.env.REACT_APP_BASE_URL + '/wp-json/gf/v2/forms/3/submissions'

    flypilotFetchWPRestAPI(formEndpoint, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    }).then((result) => {
      const isValid = result?.is_valid
      const validationMessages = result?.validation_messages

      if (!isValid) {
        setError(validationMessages[Object.keys(validationMessages)[0]])
      } else {
        onClose()
      }

      setLoading(false)
    })
  }
  console.log(error)

  useEffect(() => {
    if (options?.popup_title) {
      setPopupTitle(options?.popup_title)
    }
  }, [options])

  return (
    <div className="modal_email">
      <form onSubmit={onSubmit}>
        <button className="modal_email_close" onClick={() => onClose()}>
          <img src={CloseIcon} alt="close icon" />
        </button>
        <h3>{popupTitle}</h3>

        <label className="modal_email_label">
          <span>Email Address</span>
          <input
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
              setError(null)
            }}
            type="email"
          />
          {error && <p className="error_msg">{error}</p>}
        </label>
        <div className="button_content">
          <SearchButton>Submit</SearchButton>
          {loading && <img src={spinner} alt="Spinner gif" />}
        </div>
      </form>
    </div>
  )
}

export default Modal
