import { useEffect } from 'react'

function getCookie(name) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(';').shift()
}

const findInArray = (value, key) =>
  value?.split('//')?.some((val) => val === key)

function useCookie(name, value, ArrayKey = false, byIdx = false) {
  const loadCookie = () => {
    if (value) {
      const cookie = getCookie(name)
      let newValue
      let hasValue = false

      if (ArrayKey) {
        if (
          value.selected.length > 0 &&
          value.selected[0] &&
          value.values.length > 0
        ) {
          value.selected.forEach((item) => {
            const selectedValue = byIdx
              ? value.values[item]
              : value.values.find((val) => val[ArrayKey] === item[ArrayKey])

            if (!findInArray(cookie, selectedValue[ArrayKey])) {
              if (cookie) {
                newValue = cookie + `//${selectedValue[ArrayKey]}`
              } else {
                newValue = `${selectedValue[ArrayKey]}`
              }
            }
          })
        }
      } else if (cookie) {
        hasValue = findInArray(cookie, value)
        newValue = cookie + `//${value}`
      } else {
        newValue = value
      }

      if (!hasValue && newValue) {
        document.cookie = `${name + '=' + newValue}; path=/;`
      }
    }
  }

  useEffect(loadCookie, [name, value, ArrayKey, byIdx])
}

export { useCookie, getCookie }
