import {useEffect, useState} from 'react'
import flypilotFetchWPRestAPI from '../api/wordpress'

export default function usePages() {
  const [pages, setPages] = useState()

  function load() {
    if (pages !== undefined) {
      return
    }

    const url = '/wp-json/wp/v2/pages'

    flypilotFetchWPRestAPI(url).then(result => {
      const pagesBySlug = result.reduce((acc, page) => {
        acc[page.slug] = page
        return acc
      }, {})

      setPages(pagesBySlug)
    })
  }

  useEffect(load, [pages])

  return pages
}
