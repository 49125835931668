import {useEffect, useState} from 'react'
import flypilotFetchWPRestAPI from '../api/wordpress'

function useNeighborhoods() {
  const [neighborhoods, setNeighborhoods] = useState([])

  function loadNeighborhoods() {
    flypilotFetchWPRestAPI('/wp-json/wp/v2/neighborhoods').then((results) => {
      const element = results[4]
      results.splice(4, 1)
      results.splice(0, 0, element)
      setNeighborhoods(results
        .map(neighborhood => ({
          ...neighborhood,
          id: neighborhood.id,
          title: neighborhood.title?.rendered,
          featuredImage: neighborhood.acf?.media_items?.[0]?.item?.url,
          properties: neighborhood.properties || [],
        })),
      )
    })
  }

  useEffect(loadNeighborhoods, [])

  return neighborhoods
}

export default useNeighborhoods
