import {useEffect, useState} from 'react'
import flypilotFetchWPRestAPI from '../api/wordpress'

export default function useOptions() {
  const [options, setOptions] = useState({})

  function loadOptions() {
    flypilotFetchWPRestAPI('/wp-json/acf/v3/options/options').then(result => {
      setOptions(result?.acf)
    })
  }

  useEffect(loadOptions, [])

  return options
}
